<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="QuayLai"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center" :style="'opacity: 1'">
                            SỬA THÔNG TIN LÁI XE
                        </div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-magnify"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false" class="content-box">
            <div class="px-2 pt-3">
                <div class="row mb-4">
                    <div class="xs12">
                        <DxTextBox
                            label="Tên lái xe (*)"
                            labelMode="floating"
                            v-model="KyLenhTuyChon_ThongTinSuaLaiXe.TenLaiXe"
                            mode="text"
                            :readOnly="true"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        />
                    </div>
                </div>
                <DxValidationGroup ref="formValidation">
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxSelectBox
                                v-model="KyLenhTuyChon_ThongTinSuaLaiXe.HangGPLX"
                                ref="BienKiemSoat"
                                label="Hạng GPLX (*)"
                                labelMode="floating"
                                :dataSource="{
                                    store: DanhSachHangGPLX,
                                    paginate: true,
                                }"
                                display-expr="HangGPLX"
                                value-expr="HangGPLX"
                                :search-enabled="true"
                                :showClearButton="true"
                                styling-mode="underlined"
                                validationMessageMode="always"
                            >
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Hạng GPLX không được bỏ trống!"
                                    />
                                </DxValidator>
                            </DxSelectBox>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxDateBox
                                label="Hạn hiệu lực GPLX (*)"
                                labelMode="floating"
                                v-model:value="
                                    KyLenhTuyChon_ThongTinSuaLaiXe.HanHieuLucGPLX
                                "
                                ref="KyDenNgay"
                                displayFormat="dd/MM/yyyy"
                                :use-mask-behavior="true"
                                dropDownButtonTemplate="customIcon"
                                validationMessageMode="always"
                                styling-mode="underlined"
                                autocomplete="off"
                            >
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Hạn hiệu lực GPLX không được bỏ trống!"
                                    />
                                    <DxRangeRule
                                        :max="maxDate"
                                        :message="$i18n.t('DefaultString.MaxDate')"
                                    />
                                    <DxRangeRule
                                        :min="$Helper.Common.getEndDay(new Date())"
                                        message="Hạn hiệu lực GPLX phải lớn hơn ngày hiện tại!"
                                    />
                                </DxValidator>
                            </DxDateBox>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="xs12">
                            <DxDateBox
                                label="Ngày kết thúc hợp đồng (*)"
                                labelMode="floating"
                                v-model:value="
                                    KyLenhTuyChon_ThongTinSuaLaiXe.NgayKetThucHopDong
                                "
                                displayFormat="dd/MM/yyyy"
                                :use-mask-behavior="true"
                                dropDownButtonTemplate="customIcon"
                                validationMessageMode="always"
                                styling-mode="underlined"
                                autocomplete="off"
                            >
                                <template #customIcon="{}">
                                    <DxButton icon="mdi mdi-calendar" />
                                </template>
                                <DxValidator>
                                    <DxRequiredRule
                                        message="Ngày kết thúc HĐ không được bỏ trống!"
                                    />
                                    <DxRangeRule
                                        :max="maxDate"
                                        :message="$i18n.t('DefaultString.MaxDate')"
                                    />
                                    <DxRangeRule
                                        :min="$Helper.Common.getEndDay(new Date())"
                                        message="Ngày kết thúc HĐ phải lớn hơn ngày hiện tại!"
                                    />
                                </DxValidator>
                            </DxDateBox>
                        </div>
                    </div>
                </DxValidationGroup>

                <div class="button-bottom row justify-space-between align-center">
                    <DxButton
                        text="Hủy"
                        type="danger"
                        styling-mode="text"
                        @click="QuayLai"
                    />
                    <DxButton
                        text="Lưu"
                        type="default"
                        styling-mode="contained"
                        @click="CapNhatLaiXe"
                    />
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import {
    DxTextBox,
    DxButton,
    DxDateBox,
    DxValidator,
    DxSelectBox,
    DxValidationGroup,
} from "devextreme-vue";
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";

export default {
    components: {
        DxValidationGroup,
        DxDateBox,
        DxTextBox,
        DxButton,
        DxRequiredRule,
        DxRangeRule,
        DxValidator,
        DxSelectBox,
        DxDateBox,
        IonPage,
        IonHeader,
        IonContent,
        IonToolbar,
    },
    data() {
        return {};
    },
    computed: {
        LenhStore() {
            return this.$store.state.LenhTuyChon;
        },
        KyLenhTuyChon_ThongTinSuaLaiXe: {
            get() {
                return this.LenhStore.KyLenhTuyChon_ThongTinSuaLaiXe || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_ThongTinSuaLaiXe",
                    data: data,
                });
            },
        },
        maxDate() {
            return this.$store.state.DVVTLenh.maxDate;
        },
        DanhSachHangGPLX: {
            get() {
                return this.$store.state.ThongTinLaiXe.DanhSachHangGPLX || {};
            },
            set(data) {
                this.$store.commit("ThongTinLaiXe/Set", {
                    key: "DanhSachHangGPLX",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachLaiXe: {
            get() {
                return this.$store.state.LenhTuyChon.KyLenhTuyChon_DanhSachLaiXe || [];
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachLaiXe",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachLaiXeChieuDi: {
            get() {
                return this.LenhStore.KyLenhTuyChon_DanhSachLaiXeChieuDi || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachLaiXeChieuDi",
                    data: data,
                });
            },
        },
        KyLenhTuyChon_DanhSachLaiXeChieuVe: {
            get() {
                return this.LenhStore.KyLenhTuyChon_DanhSachLaiXeChieuVe || {};
            },
            set(data) {
                this.$store.commit("LenhTuyChon/Set", {
                    key: "KyLenhTuyChon_DanhSachLaiXeChieuVe",
                    data: data,
                });
            },
        },
    },
    watch: {},
    methods: {
        QuayLai() {
            return this.$router.go(-1);
        },
        async CapNhatLaiXe() {
            let validate = this.$refs.formValidation.instance.validate();
            if (validate.isValid) {
                let item = this.KyLenhTuyChon_ThongTinSuaLaiXe;
                const thongTinGiayToLaiXe = {
                    HangGiayPhep: item.HangGPLX,
                    iD_DnvtLaiXe: item.IdDnvtLaiXe,
                    hD_NgayBatDau: item.NgayBatDauHopDong,
                    hD_NgayKetThuc: item.NgayKetThucHopDong,
                    hanGPLX: item.HanHieuLucGPLX,
                    iD_HangGiayPhep:
                        this.DanhSachHangGPLX.find(
                            (gplx) => gplx.HangGPLX == item.HangGPLX,
                        ).IdHangGP || null,
                };

                try {
                    let result = await this.$store.dispatch(
                        "DVVTLenh/CapNhatThongTinGiayToLaiXe",
                        thongTinGiayToLaiXe,
                    );
                    if (result.status == false) {
                        this.$Helper.ThongBaoPopup({
                            message_title: "Cập nhật thông tin giấy tờ lái xe thất bại! ",
                            message: `${result.message}`,
                        });
                    } else {
                        this.$Helper.ThongBaoToast(
                            "success",
                            "Cập nhật thông tin lái xe thành công!",
                        );
                        await this.DongBoThongTinSauCapNhat();
                        // return this.$router.go(-1);
                    }
                } catch (error) {
                    console.log("🚀 ~ error:", error);
                    this.$Helper.ThongBaoPopup({
                        message_title: "Cập nhật thông tin giấy tờ lái xe thất bại!",
                        message: "Lỗi hệ thống",
                    });
                }
            } else {
                this.$Helper.autoFocusError(validate);
            }
        },
        async DongBoThongTinSauCapNhat() {
            await this.LayDanhSachLaiXe();
            this.KyLenhTuyChon_DanhSachLaiXeChieuDi[0] = this
                .KyLenhTuyChon_DanhSachLaiXeChieuDi[0]
                ? this.KyLenhTuyChon_DanhSachLaiXe.find(
                      (lx) =>
                          lx.ID_LaiXe ==
                          this.KyLenhTuyChon_DanhSachLaiXeChieuDi[0].ID_LaiXe,
                  )
                : null;
            this.KyLenhTuyChon_DanhSachLaiXeChieuDi[1] = this
                .KyLenhTuyChon_DanhSachLaiXeChieuDi[1]
                ? this.KyLenhTuyChon_DanhSachLaiXe.find(
                      (lx) =>
                          lx.ID_LaiXe ==
                          this.KyLenhTuyChon_DanhSachLaiXeChieuDi[1].ID_LaiXe,
                  )
                : null;
            this.KyLenhTuyChon_DanhSachLaiXeChieuDi[2] = this
                .KyLenhTuyChon_DanhSachLaiXeChieuDi[2]
                ? this.KyLenhTuyChon_DanhSachLaiXe.find(
                      (lx) =>
                          lx.ID_LaiXe ==
                          this.KyLenhTuyChon_DanhSachLaiXeChieuDi[2].ID_LaiXe,
                  )
                : null;
            this.KyLenhTuyChon_DanhSachLaiXeChieuVe[0] = this
                .KyLenhTuyChon_DanhSachLaiXeChieuVe[0]
                ? this.KyLenhTuyChon_DanhSachLaiXe.find(
                      (lx) =>
                          lx.ID_LaiXe ==
                          this.KyLenhTuyChon_DanhSachLaiXeChieuVe[0].ID_LaiXe,
                  )
                : null;
            this.KyLenhTuyChon_DanhSachLaiXeChieuVe[1] = this
                .KyLenhTuyChon_DanhSachLaiXeChieuVe[1]
                ? this.KyLenhTuyChon_DanhSachLaiXe.find(
                      (lx) =>
                          lx.ID_LaiXe ==
                          this.KyLenhTuyChon_DanhSachLaiXeChieuVe[1].ID_LaiXe,
                  )
                : null;
            this.KyLenhTuyChon_DanhSachLaiXeChieuVe[2] = this
                .KyLenhTuyChon_DanhSachLaiXeChieuVe[2]
                ? this.KyLenhTuyChon_DanhSachLaiXe.find(
                      (lx) =>
                          lx.ID_LaiXe ==
                          this.KyLenhTuyChon_DanhSachLaiXeChieuVe[2].ID_LaiXe,
                  )
                : null;
            return this.$router.go(-1);
        },
        async LayDanhSachLaiXe() {
            try {
                this.KyLenhTuyChon_DanhSachLaiXe = (
                    await this.$store.dispatch(
                        "LenhTuyChon/Get_DanhSachLaiXeTaoLenhTuyChon",
                    )
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách lái xe thất bại! Không thể kết nối máy chủ.`,
                );
            }
        },
    },
    created() {
        onIonViewWillEnter(async () => {});
    },
    mounted() {},
};
</script>
<style scoped></style>
